<template>
  <div class="content_box">
    <div class="inner_max">
      <Card>
        <template v-slot:header>
          <span>
            {{
              $t('register.finishPage.title', {
                individualUserName: individualUserName,
                platformName: $platform.info.fullName
              })
            }}
          </span>
          <i v-if="account && idPassed" class="el-icon-circle-check text-green"></i>
          <i v-else class="el-icon-warning-outline text-magenta"></i>
        </template>
        <div>
          <div class="mb-3">
            <div
              v-if="account && idPassed"
              v-html="$t('register.finishPage.contentDefault', { account: account, email: email })"
            ></div>

            <!-- id3沒過，有上傳文件，有帳號 -->
            <div v-else-if="account" v-html="$t('register.finishPage.contentWithoutId3WithAccount')"></div>

            <!-- id3沒過，有上傳文件，沒有帳號 -->
            <div v-else v-html="$t('register.finishPage.contentWithoutId3WithoutAccount')"></div>

            <!-- id3沒過，沒有上傳文件，有帳號 -->
            <!-- 跳轉HOME PAGE -->

            <!-- id3沒過，沒有上傳文件，沒有帳號 -->
            <!-- 跳轉HOME PAGE -->
          </div>
          <router-link
            v-if="account"
            :to="{ name: 'depositFunds' }"
            class="el-button bg-primary"
            data-testid="depositFunds"
          >
            {{ $t('register.btn.fundnow') }}
          </router-link>
          <router-link v-else :to="{ name: 'home' }" class="el-button bg-primary" data-testid="bkToHm">
            {{ $t('common.button.bkToHm') }}
          </router-link>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import Card from '@/components/Card';
import { apiID3Status } from '@/resource/register';
import { apiGetMetaTraderAccounts } from '@/resource';
import idPoaMixin from '@/mixins/idPoa';

export default {
  name: 'RegisterFinish',
  mixins: [idPoaMixin],
  components: { Card },
  data() {
    return {
      account: '',
      idPassed: false
    };
  },
  computed: {
    individualUserName() {
      return this.$store.state.common.individualUserName;
    },
    email() {
      return this.$store.state.common.email;
    },
    requireIDProof() {
      return this.$store.state.common.requireIDProof;
    },
    greenIDStatus() {
      return this.$store.state.common.greenIDStatus;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      const responses = await new Promise.all([!this.greenIDStatus ? apiID3Status() : null, this.refreshIdPoa()]);
      const id3Response = responses[0];
      if (id3Response && id3Response.data.code == 0 && id3Response.data.data) {
        this.idPassed = true;
        this.$store.commit('common/setPendingIDProofApproval', false);
        this.$store.commit('common/setRequireIDProof', false);
      }
      if (this.requireIDProof && !this.idPassed) {
        this.$router.push('/home');
      } else {
        this.getMetaTraderAccounts();
      }
    },
    async getMetaTraderAccounts() {
      await apiGetMetaTraderAccounts().then(resp => {
        if (resp.data && resp.data.length == 1 && resp.data[0].mt4_account) {
          this.account = resp.data[0].mt4_account;
          this.$store.commit('common/setOpenAccountAction', true);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .card {
  .content {
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
